<template>
    <b-card title="Permissions">
        <span style="font-weight: bold; font-size: large;">Group: {{ group_detail.group_name }} </span>
        <b-row> 
            <b-col xl="12">
                <div class="row">
                    <div class="col-3 d-none d-md-block" ></div>
                    <div class="col-md-6">
                        <div class="row" v-for="permission, index in permission_by_group.permission" :key="index">
                            <div class="col-2 d-none d-md-block"></div>
                            <div class="col-md-4" style="font-size: medium; font-weight: 500; padding: 8px 0px; text-transform: capitalize;">{{ permission.name }}</div>
                            <div class="col-md-4">
                                <div class="text-md-right" style="padding: 8px 0px; cursor: pointer;">
                                    <b-badge pill :variant="permission.status ? 'success' : 'danger'" @click="togglePermission(permission.codename)">{{ permission.status ? 'Active' : 'Inactive'}}</b-badge>
                                </div>
                            </div>
                            <div class="col-2 d-none d-md-block"></div>
                        </div>
                    </div>
                    <div class="col-3 d-none d-md-block" ></div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>
  
<script>
import {
    BAvatar,
    BButton,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BModal,
    VBModal,
    BCardText,
    BFormFile
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
  
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        BCardText,
        BFormFile,
        vSelect
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    computed: {
        ...mapState('permission', ["group_detail", "permission_by_group"])
    },

    watch: {
        group_detail(newValue, oldValue) {},
        permission_by_group(newValue, oldValue) {}
    },

    data() {
        return {
            host: "https://api-biyeruti.smicee.com",
        }
    },

    created() {
        this.$store.dispatch('permission/fetchGroupDetails', this.$route.params.id)
        this.$store.dispatch('permission/fetchPermissionsByGroup', this.$route.params.id)
    },

    methods: {
        togglePermission(codename) {
            this.$store.dispatch('permission/assignGroupPermission', {
                id: this.$route.params.id,
                group_name: this.group_detail.group_name,
                codename: codename
            }).then(result=>{
                if(result.code == 200) {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Success`,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                            text: result.response
                        }
                    })
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'ThumbsDownIcon',
                            variant: 'error',
                            text: result.response
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>